<template>
	<div
		class="expand-icon"
		:class="[{ expanded: expanded }, color]"
	>
		<TnIcon
			name="minus"
			alt="Minus"
		/>
		<TnIcon
			name="plus"
			alt="Plus"
		/>
	</div>
</template>

<script>
export default defineNuxtComponent({
	name: "ExpandIcon",

	props: {
		expanded: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: "black",
		},
	},
});
</script>

<style scoped lang="scss">
.expand-icon {
	width: 24px;
	height: 24px;
	position: relative;

	&.dark {
		color: $color-neutrals-white;
		opacity: 0.6;
	}

	svg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transition: all 300ms ease-in-out;
	}

	svg:first-of-type {
		opacity: 0;
	}

	svg:last-of-type {
		opacity: 1;
	}

	&.expanded {
		svg:first-of-type {
			opacity: 1;
			transform: rotate(180deg);
		}

		svg:last-of-type {
			opacity: 0;
			transform: rotate(180deg);
		}
	}
}
</style>
